body {
  font-family: sans-serif;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.game-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 40px;
  padding-top: 60px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  margin: 0;
  margin-bottom: 30px;
  color: #333;
  font-size: 36px;
  text-align: center;
}

p {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

button {
  padding: 20px 50px;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 10px;
  background: linear-gradient(to bottom, #ff7675, #fd79a8);
}

button:active {
  animation: glowing 1.5s infinite; /* animate when the button is clicked */
}

button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 118, 117, 0.3);
  }
  100% {
    box-shadow: 0 0 0 40px rgba(255, 118, 117, 0);
  }
}
